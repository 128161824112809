import React, { useRef, useEffect } from "react"
import styled from "styled-components"

import { Button } from "../../../reusableComponents/Button.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import youtubeIcon from "../../../../assets/images/icons/youtube-colored.svg"
import facebookIcon from "../../../../assets/images/icons/facebook-colored.svg"
import spotifyIcon from "../../../../assets/images/icons/spotify-colored.svg"

function Hero() {
  const data = useStaticQuery(graphql`
    query AboutHeroQuery {
      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/" }
          name: { in: ["about-us-hero"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { cropFocus: CENTER }
                placeholder: BLURRED
                quality: 100
                blurredOptions: { width: 10 }
              )
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Container>
        <ImageContainer>
          {data.allFile.edges.map((image, key) => (
            <Images
              key={key}
              image={getImage(image.node.childImageSharp.gatsbyImageData)}
              alt="Writers Way Solutions About hero image"
            />
          ))}
        </ImageContainer>
        <Wrapper>
          <HeaderTitle>GET TO KNOW US</HeaderTitle>
          {/* <div
            css={`
              @media screen and (max-width: 768px) {
                display: none;
              }
            `}
          ></div> */}
          <DescriptionWrapper>
            <Description>
              Welcome to Writer's Way, your ultimate destination for all things
              writing! Whether you're a seasoned wordsmith or just starting out
              on your creative journey, this website is designed to inspire,
              educate, and connect writers from all walks of life.
            </Description>
            <BottomAction>
              <ButtonContainer>
                <Button round="true" to="/contact" small>
                  Get started
                </Button>
              </ButtonContainer>
              <SocialMediaWrapper>
                <IconsWrapper
                  href="https://www.youtube.com/@WritersWaySolutions."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon src={youtubeIcon} />
                </IconsWrapper>
                <IconsWrapper
                  href="https://www.facebook.com/profile.php?id=61555616772326"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon src={facebookIcon} />
                </IconsWrapper>
                <IconsWrapper
                  href="https://open.spotify.com/show/3Mg1BrXJApuM7Gk5a86dAV"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon src={spotifyIcon} />
                </IconsWrapper>
              </SocialMediaWrapper>
            </BottomAction>
          </DescriptionWrapper>
          <Line></Line>
        </Wrapper>
      </Container>
    </>
  )
}

export default Hero

const Container = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  padding: 3rem calc((100vw - 1103px) / 2);
  padding-bottom: 6rem;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 1137px) {
    padding: 2rem 1.5rem;
  }
  @media screen and (max-width: 964px) {
    padding: 3rem 1.5rem;
    font-size: 75px;
    gap: 1.5rem;
  }

  @media screen and (max-width: 568px) {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
`

const Images = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const Wrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (max-width: 568px) {
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`

const HeaderTitle = styled.div`
  color: #2a8ae5;
  font-size: 70px;
  font-family: ${props => props.theme.fonts.tertiary} !important;
  letter-spacing: 0.8px;
  font-weight: 700;
  white-space: nowrap;
  /* position: absolute; */
  top: 5.5rem;

  @media screen and (max-width: 964px) {
    top: 4rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 50px;
    position: unset;
    white-space: wrap;
  }

  @media screen and (max-width: 568px) {
    text-align: center;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 4rem;

  @media screen and (max-width: 964px) {
    gap: 1rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 568px) {
    align-items: center;
  }
`

const Description = styled.span`
  color: #69878f;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  font-weight: 400;
  text-align: justify;
  line-height: 26px;
  letter-spacing: 0.8px;

  @media screen and (max-width: 568px) {
    text-align: center;
  }
`

const BottomAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`
const ButtonContainer = styled.div`
  @media screen and (max-width: 568px) {
    width: 100%;
  }
`

const SocialMediaWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

const IconsWrapper = styled.a`
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
`

const Line = styled.div`
  width: 119px;
  height: 10px;
  background: #2d2f2f;
  display: none;

  @media screen and (max-width: 568px) {
    display: none;
  }
`
