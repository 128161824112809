import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/sections/about/hero/Hero"
import NewsLetter from "../components/sections/home/newsLetter/NewsLetter"
import About from "../components/sections/about/about/About"
import { Helmet } from "react-helmet"

const AboutPage = () => (
  <>
    <Seo title="About" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Hero/>
    <About/>
    {/* <NewsLetter/> */}
  </>
)

export default AboutPage
