import svg1 from '../assets/images/6.svg'
import svg2 from '../assets/images/7.svg'
import svg3 from '../assets/images/8.svg'

export const about = [
    {
        img:svg1,
        alt:"WHO WE ARE",
        title:"WHO WE ARE",
        description: "Writers Way Solutions is a full-service management and representation firm exclusively representing non-fiction authors. With an eye towards innovation, We maintain an intimate yet professional relationship with publishers looking for significant deals in the Global Market.",
        reverse:false
    },
    {
        img:svg2,
        alt:"Writers Way Solutions Vission",
        title:"VISION",
        description: "At Writer's Way, we believe in the power of words to shape and transform the world around us. We understand the joys and challenges that come with the writing process, and our mission is to provide you with the tools, resources, and community support you need to thrive as a writer.",
        reverse:true 
    },
    {
        img:svg3,
        alt:"AWARD WINNING",
        title:"MISSION",
        description: "Our mission is to bring compelling stories, informative content, and thought-provoking ideas to readers worldwide. We aim to provide a platform where readers can explore a diverse range of literature and discover new voices, like yours.",
        reverse:false
    }
]