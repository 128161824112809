import React,{useRef,useEffect} from "react"
import styled from "styled-components"
import {about} from '../../../../data/about'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

function About() {
  let container = useRef(null)

  useEffect(() => {
 
    const header = container.firstElementChild

    const rowOne = header.nextSibling
    const rowTwo = rowOne.nextSibling
    const rowThree = rowTwo.nextSibling


       gsap.to(container, { duration: 0, css: { visibility: "visible" } })

       let tl = new gsap.timeline({
         scrollTrigger:{
           trigger: container,
           start:"20px 80%",
           end:"+=800px",
           scrub:2,
         }
       })

       tl.from(
        [header],
        {
          duration: 1,
          opacity: 0,
          y: -50,
          ease: "power3.inOut"
        },
        "start"
      )

      tl.from(
        rowOne.children[0],
        {
          duration:2,
          opacity: 0,
          x: -50,
          ease: "power3.inOut"
        },
        "start"
      ).from(rowOne.children[1],{duration: 2,opacity: 0,x: 50,  ease: "power3.inOut"}, .2)
      .from(
        rowTwo.children[0],
        {
          duration: 2,
          opacity: 0,
          x: 50,
          ease: "power3.inOut"
        },
        "start"
      ).from(rowTwo.children[1],{duration: 2,opacity: 0,x: -50,  ease: "power3.inOut"}, .2)
      .from(
        rowThree.children[0],
        {
          duration: 2,
          opacity: 0,
          x: -50,
          ease: "power3.inOut"
        },
        "start"
      ).from(rowThree.children[1],{duration: 2,opacity: 0,x: 50,  ease: "power3.inOut"}, .2)
 },[])
  return (
    <Container  ref={el => container = el}>
      <HeaderH3>About The Agency</HeaderH3>
      {about.map((item, index) => {
        return (
          <Row key={index} row={item.reverse}>
            <ColumnTwo>
              <CirleImage src={item.img} alt={item.alt}/>
            </ColumnTwo>
            <ColumnOne>
              <Header>{item.title}</Header>
              <Paragraph>{item.description}</Paragraph>
            </ColumnOne>
          </Row>
        )
      })}
    </Container>
  )
}

export default About


const Container = styled.div`
 ${'' /* background:pink; */}
 visibility:hidden;
 overflow:hidden;
 display:flex;
 flex-direction:column;
 width:100%;
  padding: 4rem calc((100vw - 1300px) / 2);
  position:relative;
  z-index:3;
  ${'' /* top:-50px; */}
  margin-top:-70px;

    @media screen and (max-width: 1100px){
      margin-top: -30px;
    }

    @media screen and (max-width: 983px){
      margin-top: 0;
    }
`
const HeaderH3 = styled.h3`
  display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
  font-size: clamp(3rem, 5vw, 2rem);
  color: ${props => props.theme.colors.accent5};
  padding: 0 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
` 
const Row = styled.div`
  padding: 2rem 5rem;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction: ${({row}) => (row ? "row-reverse" :  "row")};
   ${'' /* background:orange; */}

  @media screen and (max-width: 768px){
    flex-direction:column-reverse;
    padding:2rem 1rem;
  }
`
const ColumnOne = styled.div`
 height:max-content;
 padding: 2rem;
`
const Header = styled.h4`
 font-size: clamp(1.8rem, 2.5vw, 1.3rem);
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.accent7};
  font-weight: 700;
`
const Paragraph = styled.p`
 color: ${props => props.theme.colors.dark2};
  font-size: clamp(0.8rem, 5vw, 0.9rem);
  ${'' /* letter-spacing:1px; */}
  line-height:1.5;
  text-align: justify;
`

const ColumnTwo = styled.div`
 ${'' /* background:green; */}
 width:100%;
 display:flex;
 justify-content:center;

 @media screen and (max-width: 768px){
     width:60%;
 }
`


const CirleImage = styled.img`
  height:100%;
  width:100%;
  padding: 0 1rem;
  ${'' /* border-radius:50%; */}
  -o-object-fit: cover;
  object-fit: cover ;
`